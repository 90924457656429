import { useQuery } from '@apollo/client';
import { Button, MenuItem, Spinner } from '@blueprintjs/core';
import { DateRange, DateRangeInput3 } from '@blueprintjs/datetime2';
import { ItemRendererProps, MultiSelect, Select } from '@blueprintjs/select';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ReportAllJobsDocument, ReportAllJobsQuery } from '../../../__generated__/v2';
import { BarChart } from './BarChart';
import { classifications, granularities, intervals, pathways, services } from './consts';
import {
    ChartData,
    Granularity,
    MultiPropsType,
    SinglePropsType,
    StatsType,
    TimeInterval,
} from './types';
import { prepareData } from './utils';

function JobsReport() {
    const [dates, setDates] = useState<DateRange>([null, null]);
    const [granularity, setGranularity] = useState<Granularity>(Granularity.monthly);
    const [interval, setSelectedInterval] = useState<TimeInterval>(TimeInterval.allTime);
    const [classificationsSelected, setClassificationsSelected] = useState<string[]>([]);
    const [servicesSelected, setServicesSelected] = useState<string[]>([]);
    const [pathwaysSelected, setPathwaysSelected] = useState<string[]>([]);
    const [chartData, setChartData] = useState<ChartData>();

    const { data, error, loading } = useQuery<ReportAllJobsQuery>(ReportAllJobsDocument, {
        variables: {
            dateStart: dates[0],
            dateEnd: dates[1],
            classifications: classificationsSelected,
            pathways: pathwaysSelected,
            services: servicesSelected,
        },
    });

    useEffect(() => {
        if (!data) return;

        let minDate = dates[0];
        let maxDate = dates[1];

        if (!dates[0] || !dates[1]) {
            // Fill all dates period from data
            const timestamps = data.reportAllJobs.map((job) => new Date(job.plannedDate).getTime());

            minDate = new Date(Math.min(...timestamps));
            maxDate = moment(Math.max(...timestamps))
                .add(1, 'day')
                .toDate();

            setDates([minDate, maxDate]);
            intervals[TimeInterval.allTime].dates = [minDate, maxDate];
        }

        if (!Object.keys(classifications).length) {
            data.reportAllJobs.forEach((job) => {
                if (job.jobClassification && !classifications[job.jobClassification]) {
                    classifications[job.jobClassification] = job.jobClassification;
                }
                if (job.pathwayId && job.pathwayName && !pathways[job.pathwayId]) {
                    pathways[job.pathwayId] = job.pathwayName;
                }
                if (job.serviceId && job.serviceName && !services[job.serviceId]) {
                    services[job.serviceId] = job.serviceName;
                }
            });
        }

        const preparedData: ChartData = prepareData(
            data.reportAllJobs,
            minDate!,
            maxDate!,
            granularity,
        );

        setChartData(preparedData);
        // Need to avoid triggering the hook, when dates change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [granularity, data]);

    const handleDatesChange = (newDates: DateRange) => {
        setDates(newDates);
        setSelectedInterval(TimeInterval.none);
    };

    const handleGranularityChange = (newGranularity: string) => {
        setGranularity(newGranularity as Granularity);
    };

    const handleIntervalChange = (newInterval: TimeInterval) => {
        setSelectedInterval(newInterval);
        setDates(intervals[newInterval].dates);
    };

    const updateMultiSelectValue = (array: string[], newValue: string) =>
        array.includes(newValue)
            ? array.filter((value) => value !== newValue)
            : [...array, newValue];

    const handlePathwaysChange = (newValue: string) => {
        setPathwaysSelected((current) => updateMultiSelectValue(current, newValue));
    };

    const handleServicesChange = (newValue: string) => {
        setServicesSelected((current) => updateMultiSelectValue(current, newValue));
    };
    const handleClassificationsChange = (newValue: string) => {
        setClassificationsSelected((current) => updateMultiSelectValue(current, newValue));
    };

    const renderSelectItem = (
        value: string,
        { handleClick }: ItemRendererProps,
        prop: SinglePropsType,
    ) => {
        let name = '';
        if (prop === 'granularity') name = granularities[value as Granularity];
        if (prop === 'interval') name = intervals[value as TimeInterval].name;

        return (
            <MenuItem
                className="reporting__menu-select-item"
                key={value}
                text={name}
                onClick={handleClick}
                roleStructure="listoption"
            />
        );
    };

    const renderMultiSelectItem = (
        value: string,
        { handleClick }: ItemRendererProps,
        prop: MultiPropsType,
    ) => {
        let array: string[] = [];
        if (prop === 'classifications') array = classificationsSelected;
        if (prop === 'services') array = servicesSelected;
        if (prop === 'pathways') array = pathwaysSelected;

        let name = '';
        if (prop === 'classifications') name = classifications[value];
        if (prop === 'services') name = services[value];
        if (prop === 'pathways') name = pathways[value];

        const isSelected = array.includes(value);

        return (
            <MenuItem
                className="reporting__menu-select-item"
                key={value}
                text={name}
                onClick={handleClick}
                roleStructure="listoption"
                selected={isSelected}
            />
        );
    };

    const stats: StatsType = {
        initials: 0,
        directs: 0,
        indirects: 0,
        administratives: 0,
        unknown: 0,
        followUps: 0,
        ratio: 0,
    };
    (data?.reportAllJobs || []).forEach((job) => {
        if (!job.jobClassification) stats.unknown += 1;
        if (job.jobClassification === 'Initial') stats.initials += 1;
        if (job.jobClassification === 'Direct') stats.directs += 1;
        if (job.jobClassification === 'Indirect') stats.indirects += 1;
        if (job.jobClassification === 'Administrative') stats.administratives += 1;
        if (job.serviceId === 'followUp') stats.followUps += 1;
    });
    stats.ratio = stats.initials / (stats.followUps || 1);

    const pathwaysKeys = Object.keys(pathways);
    const servicesKeys = Object.keys(services);
    const classificationKeys = Object.keys(classifications);

    return (
        <>
            <menu className="reporting__menu">
                <div className="reporting__menu-filter">
                    <span>Start Date</span>
                    <DateRangeInput3
                        value={dates}
                        disabled={loading}
                        onChange={handleDatesChange}
                    />
                </div>

                <div className="reporting__menu-filter">
                    <span>Time Interval</span>
                    <Select
                        items={Object.keys(intervals).slice(1) as TimeInterval[]}
                        filterable={false}
                        disabled={loading}
                        itemRenderer={(value, props) => renderSelectItem(value, props, 'interval')}
                        onItemSelect={handleIntervalChange}
                    >
                        <Button
                            style={{ width: '160px' }}
                            className="reporting__menu-select-button"
                            text={intervals[interval].name}
                            rightIcon="double-caret-vertical"
                        />
                    </Select>
                </div>

                <div className="reporting__menu-filter">
                    <span>Granularity</span>
                    <Select
                        items={Object.keys(granularities)}
                        filterable={false}
                        disabled={loading}
                        itemRenderer={(value, props) =>
                            renderSelectItem(value, props, 'granularity')
                        }
                        onItemSelect={handleGranularityChange}
                    >
                        <Button
                            style={{ width: '120px' }}
                            className="reporting__menu-select-button"
                            text={granularities[granularity]}
                            rightIcon="double-caret-vertical"
                        />
                    </Select>
                </div>

                {pathwaysKeys.length > 0 && (
                    <div className="reporting__menu-filter">
                        <span>Pathway</span>
                        <MultiSelect
                            className="reporting__menu-select-button"
                            disabled={loading}
                            items={pathwaysKeys}
                            itemRenderer={(value, props) =>
                                renderMultiSelectItem(value, props, 'pathways')
                            }
                            onItemSelect={(value) => handlePathwaysChange(value)}
                            selectedItems={pathwaysSelected}
                            tagRenderer={(value) => pathways[value] || ''}
                            tagInputProps={{ tagProps: { minimal: true } }}
                        />
                    </div>
                )}

                {servicesKeys.length > 0 && (
                    <div className="reporting__menu-filter">
                        <span>Services</span>
                        <MultiSelect
                            className="reporting__menu-select-button"
                            disabled={loading}
                            items={servicesKeys}
                            itemRenderer={(value, props) =>
                                renderMultiSelectItem(value, props, 'services')
                            }
                            onItemSelect={(value) => handleServicesChange(value)}
                            selectedItems={servicesSelected}
                            tagRenderer={(value) => services[value] || ''}
                            tagInputProps={{ tagProps: { minimal: true } }}
                        />
                    </div>
                )}

                {classificationKeys.length > 0 && (
                    <div className="reporting__menu-filter">
                        <span>Job Classification</span>
                        <MultiSelect
                            className="reporting__menu-select-button"
                            disabled={loading}
                            items={classificationKeys}
                            itemRenderer={(value, props) =>
                                renderMultiSelectItem(value, props, 'classifications')
                            }
                            onItemSelect={(value) => handleClassificationsChange(value)}
                            selectedItems={classificationsSelected}
                            tagRenderer={(value) => classifications[value] || ''}
                            tagInputProps={{ tagProps: { minimal: true } }}
                        />
                    </div>
                )}
            </menu>

            <div className="reporting__content">
                {loading && (
                    <div className="reporting__content-cover">
                        <Spinner />
                    </div>
                )}
                {!loading && error && (
                    <div className="reporting__content-cover">No data to create the charts.</div>
                )}
                {!loading && !error && chartData && (
                    <aside className="reporting__stats">
                        <h2>Summary</h2>
                        <h3 className="reporting__stats-title">Initials</h3>
                        <div className="reporting__stats-value">{stats.initials}</div>
                        <h3 className="reporting__stats-title">Directs</h3>
                        <div className="reporting__stats-value">{stats.directs}</div>
                        <h3 className="reporting__stats-title">Indirects</h3>
                        <div className="reporting__stats-value">{stats.indirects}</div>
                        <h3 className="reporting__stats-title">Administrative</h3>
                        <div className="reporting__stats-value">{stats.administratives}</div>
                        <h3 className="reporting__stats-title">Initials : Follow Ups</h3>
                        <div className="reporting__stats-value">
                            {Math.min(stats.initials, 1)} : {Math.round(stats.ratio * 100) / 100}
                        </div>
                    </aside>
                )}
                {!loading && !error && chartData && (
                    <main className="reporting__main">
                        <BarChart chartData={chartData} />
                    </main>
                )}
            </div>
        </>
    );
}

export default JobsReport;
