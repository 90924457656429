import { Patient } from '@doc-abode/data-models';
import { isMultiAssigneeJob } from '@doc-abode/helpers';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';

import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { abortedJobStatuses } from '../visits/ContainerConst';
import { IPatientExtended } from './ListView';

export interface IpationSortedBy {
    key: string;
    direction: string;
}
export interface IListGenerate {
    jobList: IPatientExtended[];
    doubleUps: IPatientExtended[];
}

export const useListViewViewModel = () => {
    const {
        RootStore: {
            ucrStore: {
                unassignedJobs,
                assignedJobs,
                showAbortedJobs,
                hcpFilters,
                selectedDate,
                patientAlerts,
                staffAlerts,
                hcps,
                nameFilters,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [listOfJobs, setListOfJobs] = useState<IPatientExtended[]>([]);
    const [listOfDoubleUps, setListOfDoubleUps] = useState<IPatientExtended[]>([]);
    const [sortConfig, setSortConfig] = useState<IpationSortedBy>({
        key: 'startDateTime',
        direction: 'ascending',
    });
    const requestSort = (key: string) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = '';
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = (name: string) => {
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const sortByAlertsTimingAndName = (
        patientA: IPatientExtended,
        patientB: IPatientExtended,
    ): number => {
        let aLastName = patientA.lastName || '';
        let bLastName = patientB.lastName || '';
        let aStartTime =
            moment(patientA.startDateTime).set('seconds', 0).set('milliseconds', 0).valueOf() ||
            false;
        let bStartTime =
            moment(patientB.startDateTime).set('seconds', 0).set('milliseconds', 0).valueOf() ||
            false;

        let aArriveTime =
            moment(patientA.arrivedDateTime).set('seconds', 0).set('milliseconds', 0).valueOf() ||
            false;
        let bArriveTime =
            moment(patientB.arrivedDateTime).set('seconds', 0).set('milliseconds', 0).valueOf() ||
            false;

        if (patientA.hasStaffAlert && patientB.hasStaffAlert) {
            if (aStartTime && bStartTime) {
                return aStartTime < bStartTime ? -1 : 1;
            } else if (aStartTime !== bStartTime) {
                return bStartTime ? -1 : 1;
            }
        }

        if (patientA.hasStaffAlert) {
            return -1;
        } else if (patientB.hasStaffAlert) {
            return 1;
        }

        if (patientA.hasPatientAlert && patientB.hasPatientAlert) {
            if (aStartTime && bStartTime) {
                return aStartTime < bStartTime ? -1 : 1;
            } else if (aStartTime !== bStartTime) {
                return bStartTime ? -1 : 1;
            }
        }

        if (patientA.hasPatientAlert) {
            return -1;
        } else if (patientB.hasPatientAlert) {
            return 1;
        }

        if (aStartTime && bStartTime && aStartTime !== bStartTime) {
            return aStartTime < bStartTime ? -1 : 1;
        } else if (aStartTime !== bStartTime) {
            return bStartTime ? -1 : 1;
        }

        if (aArriveTime && bArriveTime) {
            return aArriveTime < bArriveTime ? 1 : -1;
        }

        return aLastName.localeCompare(bLastName);
    };

    useMemo(() => {
        const assignedJobsFiltered = (assignedJobs as Patient[]).filter((job) => {
            return moment(job.startDateTime || job.dateOfVisit).isSame(selectedDate, 'day');
        });

        const filteredJobsAssigned = showAbortedJobs
            ? assignedJobsFiltered
            : assignedJobsFiltered.filter(
                  (j) =>
                      !abortedJobStatuses.includes(j.jobStatus) ||
                      (j.buddyJobStatus && !abortedJobStatuses.includes(j.buddyJobStatus)),
              );

        const filteredUnassignedJobs = unassignedJobs.filter((job: any) =>
            moment(job.dateOfVisit).isSame(selectedDate, 'day'),
        );

        let jobs = [...filteredUnassignedJobs, ...filteredJobsAssigned];

        if (
            hcpFilters?.hcpType?.length ||
            hcpFilters?.availability?.length ||
            nameFilters.staffName?.length ||
            hcpFilters?.band?.length ||
            hcpFilters?.gender?.length
        ) {
            let filteredList = filteredJobsAssigned.filter((value: Patient) => {
                let staffNameStatus: boolean = true;
                if (nameFilters.staffName.length > 0) {
                    staffNameStatus = nameFilters.staffName.some((name: any) => {
                        return (
                            value.hcpName
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.buddyName
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.buddyId
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.hcpId?.toLowerCase().includes(String(name).toLowerCase().trim())
                        );
                    });
                }

                return staffNameStatus;
            });

            if (
                hcpFilters.hcpType.length ||
                hcpFilters.availability.length ||
                hcpFilters.band.length ||
                hcpFilters.gender.length
            ) {
                filteredList = filteredList.filter((value: Patient) => {
                    let staffNameStatus: boolean = false;

                    staffNameStatus = hcps.some(({ userId }: { userId: any }) => {
                        return value.buddyId === userId || value.hcpId === userId;
                    });

                    return staffNameStatus;
                });
            }
            jobs = [...filteredList];
        }

        // remove any duplicates from assigned and unassigned. (mainly double up causing this.)
        jobs = uniqBy(jobs, 'id');

        // find all the double ups and duplicate their id so they appear twice in the list
        // as part of the duplication, we map the buddy fields to the job fields to simplify the display logic in the detail rows
        // to ensure warnings are still correctly resolved, we need to inverse the common fields (status, ids) that are used for checks
        let doubleUps: Patient[] = [];
        jobs.forEach((patient: Patient) => {
            if (isMultiAssigneeJob(patient)) {
                doubleUps.push({
                    ...patient,
                    jobStatus: patient.buddyJobStatus,
                    hcpName: patient.buddyName,
                    hcpId: patient.buddyId,
                    hcpAbortedDateTime: patient.buddyHcpAbortedDateTime,
                    finishedDateTime: patient.buddyFinishedDateTime,
                    arrivedDateTime: patient.buddyArrivedDateTime,
                    buddyJobStatus: patient.jobStatus,
                    buddyId: patient.hcpId,
                    buddyName: patient.hcpName,
                } as Patient);
            }
        });

        setListOfDoubleUps(doubleUps);
        jobs = [...jobs, ...doubleUps];

        const unresolvedStaffAlert = new Set(staffAlerts.map((alert: any) => alert.userId));
        const jobList = jobs
            .map((patient: IPatientExtended): IPatientExtended => {
                const hasPatientAlert: boolean = patientAlerts.some((alert: any) => {
                    const isSame = moment(alert.createdAt).isSame(selectedDate, 'day');
                    return alert.jobId === patient.id && isSame;
                });

                patient.hasPatientAlert = hasPatientAlert;
                patient.hasStaffAlert =
                    unresolvedStaffAlert.has(patient.hcpId || '') ||
                    unresolvedStaffAlert.has(patient.buddyId || '')
                        ? true
                        : false;

                return patient;
            })
            .sort((a: any, b: any) => {
                const belongsToAWithAlerts = a.hasPatientAlert || a.hasStaffAlert;
                const belongsToBWithAlerts = b.hasPatientAlert || b.hasStaffAlert;

                if (belongsToAWithAlerts) {
                    return sortByAlertsTimingAndName(a, b);
                }

                if (belongsToBWithAlerts) {
                    return sortByAlertsTimingAndName(a, b);
                }

                if (sortConfig.direction === '') {
                    return sortByAlertsTimingAndName(a, b);
                }

                if (
                    a[sortConfig.key] !== null &&
                    b[sortConfig.key] !== null &&
                    a[sortConfig.key] !== undefined &&
                    b[sortConfig.key] !== undefined
                ) {
                    if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                } else {
                    if (a[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                }
                return 0;
            });

        setListOfJobs(jobList);
    }, [
        assignedJobs,
        hcpFilters,
        hcps,
        nameFilters.staffName,
        patientAlerts,
        selectedDate,
        showAbortedJobs,
        staffAlerts,
        unassignedJobs,
        sortConfig,
    ]);

    return {
        listOfDoubleUps,
        listOfJobs,
        sortConfig,
        setSortConfig,
        requestSort,
        getClassNamesFor,
        sortByAlertsTimingAndName,
    };
};
