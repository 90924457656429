import moment from 'moment';

import { ReportAllJobsQuery } from '../../../__generated__/v2';
import { classificationsSortOrder } from './consts';
import { ChartData, Granularity } from './types';

export const prepareData = (
    data: ReportAllJobsQuery['reportAllJobs'],
    minDate: Date,
    maxDate: Date,
    granularity: Granularity,
): ChartData => {
    let format: string;
    let unit: moment.unitOfTime.DurationConstructor;

    switch (granularity) {
        case 'daily':
            format = 'DD MMM YYYY';
            unit = 'day';
            break;
        case 'weekly':
            format = '[w]W GGGG';
            unit = 'week';
            break;
        case 'monthly':
            format = 'MMM YYYY';
            unit = 'month';
            break;
        default:
            format = 'DD MMM YYYY';
            unit = 'day';
    }

    const classificationGrouped = new Map<string, Map<string, number>>();

    data.forEach((job) => {
        if (!job.plannedDate) return;

        const classification = job.jobClassification || 'No Classification';
        const dateKey = moment(job.plannedDate).format(format);

        if (!classificationGrouped.has(classification)) {
            classificationGrouped.set(classification, new Map<string, number>());
        }

        const dateMap = classificationGrouped.get(classification)!;

        dateMap.set(dateKey, (dateMap.get(dateKey) ?? 0) + 1);
    });

    const labels: string[] = [];
    const current = moment(minDate).startOf(unit);
    const last = moment(maxDate).startOf(unit);

    while (current.isBefore(last, unit) || current.isSame(last, unit)) {
        labels.push(current.format(format));
        current.add(1, unit);
    }

    const datasets = Array.from(classificationGrouped.entries())
        .sort((a, b) => {
            const aIndex = classificationsSortOrder.indexOf(a[0]);
            const bIndex = classificationsSortOrder.indexOf(b[0]);
            return aIndex - bIndex;
        })
        .map(([classification, dateMap]) => {
            const dataArr = labels.map((label) => dateMap.get(label) || 0);
            return { label: classification, data: dataArr };
        });

    return { labels, datasets };
};
