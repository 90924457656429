import { DateRange } from '@blueprintjs/datetime2';
import moment from 'moment';

import { Granularity, TimeInterval } from './types';

export const minBarsForLabels = 100;

export const classificationsSortOrder = ['Initial', 'Direct', 'Indirect', 'Administrative'];

// Classifications are "jobClassification" column from connected LookupValues table
export const classifications: Record<string, string> = {};

// Services are "services" column from Visit table
export const services: Record<string, string> = {};

// Pathways are "referralPathway" from Admission table
export const pathways: Record<string, string> = {};

export const granularities: Record<Granularity, string> = {
    [Granularity.daily]: 'Daily',
    [Granularity.weekly]: 'Weekly',
    [Granularity.monthly]: 'Monthly',
};

const tomorrow = moment().add(1, 'day').startOf('day').toDate();
export const intervals: Record<TimeInterval, { name: string; dates: DateRange }> = {
    [TimeInterval.none]: { name: '---', dates: [null, null] },
    [TimeInterval.allTime]: { name: 'All Time', dates: [null, null] },
    [TimeInterval.today]: { name: 'Today', dates: [moment().startOf('day').toDate(), tomorrow] },
    [TimeInterval.yesterday]: {
        name: 'Yesterday',
        dates: [
            moment().subtract(1, 'day').startOf('day').toDate(),
            moment().startOf('day').toDate(),
        ],
    },
    [TimeInterval.thisWeek]: {
        name: 'This Week',
        dates: [moment().startOf('isoWeek').toDate(), tomorrow],
    },
    [TimeInterval.thisMonth]: {
        name: 'This Month',
        dates: [moment().startOf('month').toDate(), tomorrow],
    },
    [TimeInterval.thisYear]: {
        name: 'This Year',
        dates: [moment().startOf('year').toDate(), tomorrow],
    },
    [TimeInterval.lastWeek]: {
        name: 'Last Week',
        dates: [moment().subtract(1, 'week').toDate(), tomorrow],
    },
    [TimeInterval.lastMonth]: {
        name: 'Last Month',
        dates: [moment().subtract(1, 'month').toDate(), tomorrow],
    },
    [TimeInterval.last3Month]: {
        name: 'Last 3 Months',
        dates: [moment().subtract(3, 'months').toDate(), tomorrow],
    },
    [TimeInterval.lastYear]: {
        name: 'Last Year',
        dates: [moment().subtract(1, 'year').toDate(), tomorrow],
    },
    [TimeInterval.fiscalYear]: {
        name: 'Fiscal Year',
        dates: (() => {
            const currentYear = moment().year();
            let start = moment([currentYear, 3, 1]).startOf('day');

            if (moment().isBefore(start)) {
                start = start.subtract(1, 'year');
            }

            const end = start.clone().add(1, 'year').endOf('day');

            return [start.toDate(), end.toDate()];
        })(),
    },
};
