import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { classificationsSortOrder, minBarsForLabels } from './consts';
import { ChartData } from './types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Colors,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
);

export const BarChart: React.FC<{ chartData: ChartData }> = ({ chartData }) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
                ticks: {
                    precision: 0,
                },
            },
        },
        plugins: {
            colors: {
                enabled: true,
            },
            legend: {
                labels: {
                    sort: (a: any, b: any) =>
                        classificationsSortOrder.indexOf(a.text) -
                        classificationsSortOrder.indexOf(b.text),
                },
            },
            datalabels: {
                formatter: (value: number) =>
                    value && chartData.labels.length < minBarsForLabels ? value : '',
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};
