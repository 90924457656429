import { Patient } from '@doc-abode/data-models';
import { isMultiAssigneeJob } from '@doc-abode/helpers';
import { useMemo } from 'react';

import { isNonEnglishSpeaker } from '../../../../helpers/isNonEnglishSpeaker';
import { getHcp } from '../../../../helpers/ucr/getHcp';
import {
    hasCarRequiredWarning,
    hasComplexCare,
    hasGenderWarning,
    hasGeolocationError,
    hasLanguageWarning,
    hasPlannedStartTimeInThePast,
    hasS1ReferralWarning,
    hasUnresolvedPatientAlerts,
    isBreachOfEarliestArrival,
    isBreachOfLatestArrival,
} from '../../../../helpers/ucr/getWarnings';
import { isPartiallyAssignedJob } from '../../../../helpers/ucr/isPartiallyAssignedJob';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';

interface Props {
    job: Patient;
}

export interface HcpSpecificWarnings {
    hasLanguageWarning: boolean;
    hasGenderWarning: boolean;
}

interface FlagHcpWarningInput {
    hcp1SpecificWarnings?: HcpSpecificWarnings;
    hcp2SpecificWarnings?: HcpSpecificWarnings;
}

function flagLanguageWarning(hcpWarnings: FlagHcpWarningInput): boolean {
    if (!hcpWarnings.hcp1SpecificWarnings && !hcpWarnings.hcp2SpecificWarnings) {
        return false;
    }

    if (hcpWarnings.hcp1SpecificWarnings && hcpWarnings.hcp2SpecificWarnings) {
        return (
            hcpWarnings.hcp1SpecificWarnings.hasLanguageWarning &&
            hcpWarnings.hcp2SpecificWarnings.hasLanguageWarning
        );
    }

    if (hcpWarnings.hcp1SpecificWarnings) {
        return hcpWarnings.hcp1SpecificWarnings.hasLanguageWarning;
    }

    if (hcpWarnings.hcp2SpecificWarnings) {
        return hcpWarnings.hcp2SpecificWarnings.hasLanguageWarning;
    }

    return false;
}

function flagGenderWarning(hcpWarnings: FlagHcpWarningInput): boolean {
    if (!hcpWarnings.hcp1SpecificWarnings && !hcpWarnings.hcp2SpecificWarnings) {
        return false;
    }

    if (hcpWarnings.hcp1SpecificWarnings && hcpWarnings.hcp2SpecificWarnings) {
        return (
            hcpWarnings.hcp1SpecificWarnings.hasGenderWarning &&
            hcpWarnings.hcp2SpecificWarnings.hasGenderWarning
        );
    }

    if (hcpWarnings.hcp1SpecificWarnings) {
        return hcpWarnings.hcp1SpecificWarnings.hasGenderWarning;
    }

    if (hcpWarnings.hcp2SpecificWarnings) {
        return hcpWarnings.hcp2SpecificWarnings.hasGenderWarning;
    }

    return false;
}

export function useWarningConditions({ job }: Props) {
    const {
        RootStore: {
            ucrStore: { warnings, patientAlerts },
            usersStore: { hcpUsers },
            configStore: { pathways, adminTypes, isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    return useMemo(() => {
        const warningStatus = warnings[job?.id]?.length > 0;
        const hasPatientAlerts = hasUnresolvedPatientAlerts(job, patientAlerts);

        const hasAddressWarning = hasGeolocationError(warnings[job?.id]);

        let hasMissingS1Referral = false;
        if (isFeatureEnabled('s1Enabled')) {
            hasMissingS1Referral = hasS1ReferralWarning(job, adminTypes, pathways);
        }

        const hasPlannedTimeInPast = hasPlannedStartTimeInThePast(job);

        const hasEarliestTime = !!job.availableFrom;
        const hasEarliestTimeBreach = isBreachOfEarliestArrival(job);
        const hasLatestTime = !!job.availableTo;
        const hasLatestTimeBreach = isBreachOfLatestArrival(job);

        const isDoubleUp = isMultiAssigneeJob(job);

        const isPartiallyAssigned = isPartiallyAssignedJob(job);
        const hasDoubleUpWarning = isDoubleUp && isPartiallyAssigned;

        const hasCarRequired = hasCarRequiredWarning(job);
        const hasComplexCareWarning = hasComplexCare(job);
        const hasPreJobNotes = !!job.notes;
        const hasNonEnglishSpeaker = isNonEnglishSpeaker(job);

        // Warnings by HCP
        const hcp1 = getHcp(hcpUsers, job.hcpId);
        const hcp2 = getHcp(hcpUsers, job.buddyId);

        let hcp1SpecificWarnings: HcpSpecificWarnings | undefined;
        if (hcp1) {
            hcp1SpecificWarnings = {
                hasGenderWarning: hasGenderWarning(hcp1, job.staffPreferredGender),
                hasLanguageWarning: hasLanguageWarning(hcp1, job.languagesSpoken),
            };
        }

        let hcp2SpecificWarnings: HcpSpecificWarnings | undefined;
        if (hcp2) {
            hcp2SpecificWarnings = {
                hasGenderWarning: hasGenderWarning(hcp2, job.staffPreferredGender),
                hasLanguageWarning: hasLanguageWarning(hcp2, job.languagesSpoken),
            };
        }

        const flagJobHasGenderWarning = flagGenderWarning({
            hcp1SpecificWarnings,
            hcp2SpecificWarnings,
        });
        const flagJobHasLanguageWarning = flagLanguageWarning({
            hcp1SpecificWarnings,
            hcp2SpecificWarnings,
        });

        // Various warning conditions roll up into a general warning
        // The purpose of the roll-up is to ensure the most critical warnings are always flagged
        // even when there is insufficient space to show all icons on a job tile
        const hasGeneralWarning = warningStatus || hasMissingS1Referral || flagJobHasGenderWarning;

        return {
            hasGeneralWarning,
            hasUnresolvedPatientAlerts: hasPatientAlerts,
            hasAddressWarning,
            hasS1ReferralWarning: hasMissingS1Referral,
            hasPlannedTimeInPast,
            hasEarliestTime,
            hasLatestTime,
            hasEarliestTimeBreach,
            hasLatestTimeBreach,
            isDoubleUp,
            isPartiallyAssigned,
            hasDoubleUpWarning,
            hasCarRequired,
            hasComplexCare: hasComplexCareWarning,
            hasPreJobNotes,
            hasNonEnglishSpeaker,
            hasGenderWarning: flagJobHasGenderWarning,
            hasLanguageWarning: flagJobHasLanguageWarning,
            hcp1SpecificWarnings,
            hcp2SpecificWarnings,
        };
    }, [warnings, job, patientAlerts, isFeatureEnabled, hcpUsers, adminTypes, pathways]);
}
