import { AlertStatus, JobStatus, Patient } from '@doc-abode/data-models';
import { formatNameLastMiddleFirst, isMultiAssigneeJob } from '@doc-abode/helpers';
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { getHcp } from '../helpers/ucr/getHcp';
import { IFilterOption, IHcpExtended, IHcpFilter, INameFilter } from '../interfaces/ucr';
import { IHCPBreakdown, IJobFilter, PatientWithAlert } from './MapStoreTypes';

const defaultJobFilters: IJobFilter = {
    referralPathway: [],
    disposition: [],
    careComplexity: [],
    jobStatus: [],
    carRequired: undefined,
    warning: [],
    referrer: [],
};

const defaultNameFilters: INameFilter = {
    staffName: [],
    patientName: [],
};
const defaultHcpFilters: IHcpFilter = {
    availability: [],
    staffName: [],
    hcpType: [],
    band: [],
    gender: [],
};

interface ITimes {
    abortedDateTime: string;
    finishedDateTime: string;
    arrivedDateTime: string;
    madeCurrentDateTime: string;
}

class MapStore {
    constructor(rootStore: any, refreshTimeout = 60000) {
        makeObservable(this, {
            jobs: observable,
            hcps: observable,
            assignedJobs: observable,
            unassignedJobs: observable,
            jobFilters: observable,
            nameFilters: observable,
            showAbortedJobs: observable,
            showUnassignedJobs: observable,
            loadingJobs: observable,
            processingJobs: observable,
            boundedArea: observable,
            appliedFilters: observable,
            refetchJobs: observable,
            focusedHCPBreakdownOfJobs: observable,
            completedBreakdown: observable,
            expandedPanels: observable,
            hcpsBreakdownInfo: observable,
            assignedJobsFiltered: observable,
            unassignedJobsFilter: observable,
            setJobs: action,
            setHcps: action,
            setHcpFilters: action,
            setJobFilters: action,
            setNameFilters: action,
            clearAllFilters: action,
            setShowAbortedJobs: action,
            setShowUnassignedJobs: action,
            setLoadingJobs: action,
            setBoundedArea: action,
            setJobRefresher: action,
            setHCPBreakdown: action,
            setHCPBreakdownReload: action,
            removeHCPBreakdown: action,
            resetHCPBreakdown: action,
            setCompletedBreakdown: action,
            expandHcpPanel: action,
        });

        this.rootStore = rootStore;
        this.refreshTimeout = refreshTimeout;
    }

    rootStore: any;

    refreshTimeout: number;

    jobs: Patient[] = [];
    hcps: IHcpExtended[] = [];

    hcpsBreakdownInfo: Record<string, IHCPBreakdown[]> = {};
    assignedJobs: Patient[] = [];
    unassignedJobs: Patient[] = [];
    assignedJobsFiltered: PatientWithAlert[] = [];
    unassignedJobsFilter: PatientWithAlert[] = [];
    jobFilters = defaultJobFilters;
    nameFilters = defaultNameFilters;
    hcpFilters: IHcpFilter = defaultHcpFilters;
    showAbortedJobs = false;
    showUnassignedJobs = true;
    appliedFilters = 1;

    loadingJobs = false;
    processingJobs = true;

    boundedArea: IHCPBreakdown | undefined = undefined;

    focusedHCPBreakdownOfJobs: Record<string, IHCPBreakdown[]> = {};
    completedBreakdown: Record<string, boolean> = {};
    expandedPanels: Record<string, boolean> = {};

    refetchJobs = () => {};

    setLoadingJobs = (loading: boolean) => {
        this.loadingJobs = loading;
    };

    setHcps = (hcps: IHcpExtended[]) => {
        this.hcps = hcps;
        this.setHcpBreakdownInfo();
    };

    setJobs = (jobs: Patient[], isFiltered?: boolean) => {
        if (!jobs) {
            return;
        }
        this.processingJobs = true;

        const assignedJobs: Patient[] = [];
        const unassignedJobs: Patient[] = [];

        jobs.forEach((job) => {
            const isDoubleUp = isMultiAssigneeJob(job);
            if (
                (job.jobStatus !== JobStatus.PENDING || job.buddyJobStatus !== JobStatus.PENDING) &&
                this.rootStore.usersStore.users.find(
                    (hcp: any) =>
                        hcp.userId === job.hcpId || (isDoubleUp && hcp.userId === job.buddyId),
                )
            ) {
                assignedJobs.push(job);
            }
            if (
                job.jobStatus === JobStatus.PENDING ||
                (isDoubleUp && job.buddyJobStatus === JobStatus.PENDING && !job.buddyId)
            ) {
                unassignedJobs.push(job);
            }
        });

        this.assignedJobs = assignedJobs;
        this.unassignedJobs = unassignedJobs
            .sort((job1: Patient, job2: Patient) =>
                job1.firstName && job2.firstName ? job1.firstName.localeCompare(job2.firstName) : 0,
            )
            .sort((job1: Patient, job2: Patient) =>
                job1.lastName && job2.lastName ? job1.lastName.localeCompare(job2.lastName) : 0,
            )
            .sort((job1: Patient, job2: Patient) =>
                moment(job1.startDateTime || job1.dateOfVisit).diff(
                    job2.startDateTime || job2.dateOfVisit,
                ),
            );

        if (!isFiltered) {
            this.jobs = jobs;
        }

        const filterUnassignedJobs = (job: Patient) => {
            let isntInvalid: boolean = true;
            const buddyIsAborted =
                job.buddyJobStatus === JobStatus.CONTROLLER_ABORTED ||
                job.buddyJobStatus === JobStatus.HCP_ABORTED;
            const hcpIsAborted =
                job.jobStatus === JobStatus.CONTROLLER_ABORTED ||
                job.jobStatus === JobStatus.HCP_ABORTED;

            if (buddyIsAborted && hcpIsAborted && isMultiAssigneeJob(job)) isntInvalid = false;
            if (hcpIsAborted && !isMultiAssigneeJob(job)) isntInvalid = false;

            return isntInvalid;
        };

        this.assignedJobsFiltered = assignedJobs
            .filter((job) => job.disposition !== 'admin')
            .filter((job) =>
                moment(job.startDateTime || job.dateOfVisit).isSame(
                    this.rootStore.ucrStore.selectedDate,
                    'day',
                ),
            )
            .filter((job) => (this.showAbortedJobs ? true : filterUnassignedJobs(job)))
            .map((job) => {
                const hasPatientAlert: boolean = this.rootStore.ucrStore.patientAlerts.some(
                    (alert: any) => {
                        return alert.jobId === job.id;
                    },
                );
                return { job, hasPatientAlert };
            });

        const recordOfIds: string[] = this.assignedJobsFiltered.map((model) => model.job.id);

        this.unassignedJobsFilter = unassignedJobs
            .filter((job) => job.disposition !== 'admin')
            .filter((job) =>
                moment(job.dateOfVisit).isSame(this.rootStore.ucrStore.selectedDate, 'day'),
            )
            .filter((job) => (this.showAbortedJobs ? true : filterUnassignedJobs(job)))
            .filter((job) => recordOfIds.indexOf(job.id) === -1)
            .map((job) => {
                const hasPatientAlert: boolean = this.rootStore.ucrStore.patientAlerts.some(
                    (alert: any) => {
                        return alert.jobId === job.id;
                    },
                );
                return { job, hasPatientAlert };
            });

        const latlongRecord: Record<string, Patient[]> = {};

        const assignToLatLong = (patient: Patient) => {
            if (!latlongRecord[`${patient.latitude}${patient.longitude}`]) {
                latlongRecord[`${patient.latitude}${patient.longitude}`] = [];
            }
            latlongRecord[`${patient.latitude}${patient.longitude}`].push(patient);
        };

        this.unassignedJobsFilter.forEach((patient) => assignToLatLong(patient.job));
        this.assignedJobsFiltered.forEach((patient) => assignToLatLong(patient.job));

        // this is in place instead of an info window for now if jobs are exact same latlong we add a tiny amount of long so they pins don't all cluster on top.
        Object.values(latlongRecord).forEach((patients) => {
            if (patients.length > 1) {
                patients.forEach((patient, i) => {
                    patient.longitude = (patient.longitude || 0) + 0.00001 * i;
                });
            }
        });

        this.setHcpBreakdownInfo();

        this.processingJobs = false;
    };

    setJobFilters = (
        name: string,
        value?: boolean | string | string[] | IFilterOption | IFilterOption[],
    ) => {
        this.jobFilters = {
            ...this.jobFilters,
            [name]: value ?? defaultJobFilters[name],
        };
        this.setAppliedFilters();
    };
    setHcpFilters = (name: string, value?: string | string[] | IFilterOption | IFilterOption[]) => {
        this.hcpFilters = {
            ...this.hcpFilters,
            [name]: value || defaultHcpFilters[name],
        };
        this.setAppliedFilters();
    };
    setNameFilters = (name: string, value: string | string[]) => {
        this.nameFilters = {
            ...this.nameFilters,
            [name]: value || defaultNameFilters[name],
        };
    };

    setAppliedFilters = () => {
        const jobFilters: number = Object.values(this.jobFilters).reduce((sum, val) => {
            if (Array.isArray(val)) {
                return sum + val.length;
            } else if (!!val) {
                return sum + 1;
            }
            return sum;
        }, 0);
        const hcpFilters = Object.values(this.hcpFilters).reduce((sum, val) => {
            if (Array.isArray(val)) {
                return sum + val.length;
            } else if (!!val) {
                return sum + 1;
            }
            return sum;
        }, 0);
        const showAborted = this.showAbortedJobs ? 1 : 0;
        const showUnassigned = this.showUnassignedJobs ? 1 : 0;

        this.appliedFilters = hcpFilters + jobFilters + showAborted + showUnassigned;
    };

    setShowAbortedJobs = (value: boolean) => {
        this.showAbortedJobs = value;
        this.setJobs(this.jobs);
        this.setAppliedFilters();
    };

    setShowUnassignedJobs = (value: boolean) => {
        this.showUnassignedJobs = value;
        this.setJobs(this.jobs);
        this.setAppliedFilters();
    };

    clearAllFilters = () => {
        this.jobFilters = defaultJobFilters;
        this.hcpFilters = defaultHcpFilters;
        this.showAbortedJobs = false;
        this.showUnassignedJobs = false;
        this.setJobs(this.jobs);
        this.setAppliedFilters();
    };

    setBoundedArea = (breakdown: IHCPBreakdown | undefined) => {
        this.boundedArea = breakdown;
    };

    setJobRefresher = (refetchJobs: () => void) => {
        this.refetchJobs = refetchJobs;
    };

    setCompletedBreakdown = (value: boolean, userId: string) => {
        this.completedBreakdown[userId] = value;
        this.completedBreakdown = { ...this.completedBreakdown };
    };

    setHCPBreakdown = (breakdownArray: IHCPBreakdown[], userId: string, dontRefresh?: boolean) => {
        if (this.expandedPanels[userId] && !this.completedBreakdown[userId]) {
            this.focusedHCPBreakdownOfJobs[userId] = breakdownArray.filter((breakdown) => {
                return (
                    breakdown.status !== JobStatus.COMPLETED &&
                    breakdown.status !== JobStatus.HCP_ABORTED &&
                    breakdown.status !== JobStatus.CONTROLLER_ABORTED
                );
            });
        } else {
            this.focusedHCPBreakdownOfJobs[userId] = breakdownArray;
        }

        if (!dontRefresh) {
            this.focusedHCPBreakdownOfJobs = { ...this.focusedHCPBreakdownOfJobs };
        }
    };

    // force a reload of state.
    setHCPBreakdownReload = () => {
        this.focusedHCPBreakdownOfJobs = { ...this.focusedHCPBreakdownOfJobs };
    };

    resetHCPBreakdown = () => {
        this.focusedHCPBreakdownOfJobs = {};
    };

    removeHCPBreakdown = (userId: string) => {
        delete this.focusedHCPBreakdownOfJobs[userId];
        this.focusedHCPBreakdownOfJobs = { ...this.focusedHCPBreakdownOfJobs };
    };

    expandHcpPanel = (state: boolean, hcpId: string) => {
        this.expandedPanels = { ...this.expandedPanels, [hcpId]: state };
    };

    setHcpBreakdownInfo = () => {
        this.hcpsBreakdownInfo = {};

        const unresolvedAlertFiltered = this.rootStore.ucrStore.patientAlerts.filter(
            (alert: any) => alert.status === AlertStatus.OPEN,
        );

        const getTimes = (job: Patient, isBuddy: boolean): ITimes => {
            const abortedDateTime = moment(job.hcpAbortedDateTime).format('HH:mm') || '';
            const finishedDateTime =
                moment(!isBuddy ? job?.finishedDateTime : job?.buddyFinishedDateTime).format(
                    'HH:mm',
                ) || '';

            const arrivedDateTime =
                moment(!isBuddy ? job?.arrivedDateTime : job?.buddyArrivedDateTime).format(
                    'HH:mm',
                ) || '';

            const madeCurrentDateTime = moment(
                !isBuddy ? job?.madeCurrentDateTime : job?.buddyMadeCurrentDateTime,
            ).format('HH:mm');

            return {
                abortedDateTime: abortedDateTime === 'Invalid date' ? '' : abortedDateTime,
                finishedDateTime: finishedDateTime === 'Invalid date' ? '' : finishedDateTime,
                arrivedDateTime: arrivedDateTime === 'Invalid date' ? '' : arrivedDateTime,
                madeCurrentDateTime:
                    madeCurrentDateTime === 'Invalid date' ? '' : madeCurrentDateTime,
            };
        };

        // convert hh:mm to * hour[s] * minute[s].
        const niceTimeString = (timestring?: string): string => {
            let hours, minutes;
            if (timestring) {
                const split = timestring.split(':');

                hours = parseInt(split[0]);
                minutes = parseInt(split[1]);
            }
            if (hours && minutes) {
                return ` ${hours} ${hours > 1 ? 'hours' : 'hour'} ${minutes} ${
                    minutes > 1 ? 'minutes' : 'minute'
                }`;
            }
            if (hours) {
                return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
            }
            if (minutes) {
                return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} `;
            }
            return '';
        };

        const mapHCPInformationToRecord = (id: string, job: Patient, isBuddy: boolean) => {
            const hcp = getHcp(this.hcps, id);

            if (hcp) {
                const { finishedDateTime, abortedDateTime, arrivedDateTime, madeCurrentDateTime } =
                    getTimes(job, isBuddy);

                const status = isBuddy ? job.buddyJobStatus : job.jobStatus;
                const hasPatientAlert: boolean = unresolvedAlertFiltered.some((alert: any) => {
                    return alert.jobId === job.id;
                });

                const info = {
                    status,
                    name: formatNameLastMiddleFirst(job),
                    postcode: job.postCode,
                    expectedDateTimeUnix: moment(job.startDateTime).valueOf(),
                    expectedDateTime: moment(job.startDateTime).format('HH:mm'),
                    duration: niceTimeString(job.duration),
                    abortedDateTime: abortedDateTime,
                    finishedDateTime: finishedDateTime,
                    arrivedDateTime: arrivedDateTime,
                    madeCurrentDateTime: madeCurrentDateTime,
                    latitude: job.latitude,
                    longitude: job.longitude,
                    isBuddy,
                    jobId: job.id,
                    patientAlert: hasPatientAlert,
                };

                if (!this.hcpsBreakdownInfo[id]) {
                    this.hcpsBreakdownInfo[id] = [info];
                } else if (
                    this.hcpsBreakdownInfo[id].filter((hcpInfo) => hcpInfo.jobId === info.jobId)
                        .length === 0
                ) {
                    this.hcpsBreakdownInfo[id].push(info);
                } else {
                    this.hcpsBreakdownInfo[id].forEach((hcpInfo) => {
                        if (hcpInfo.jobId === info.jobId) {
                            hcpInfo = info;
                        }
                    });
                }
            }
        };

        const mapToRecord = (job: Patient) => {
            if (job.buddyId) mapHCPInformationToRecord(job.buddyId, job, true);
            if (job.hcpId) mapHCPInformationToRecord(job.hcpId, job, false);
        };

        this.assignedJobsFiltered.forEach((model) => mapToRecord(model.job));
        this.unassignedJobsFilter.forEach((model) => mapToRecord(model.job));

        Object.keys(this.hcpsBreakdownInfo).forEach((key) => {
            let i = 0;

            this.hcpsBreakdownInfo[key] = this.hcpsBreakdownInfo[key].slice().sort((a, b) => {
                if (a.expectedDateTimeUnix && b.expectedDateTimeUnix) {
                    return a.expectedDateTimeUnix > b.expectedDateTimeUnix ? 1 : -1;
                } else {
                    return -1;
                }
            });

            this.hcpsBreakdownInfo[key] = this.hcpsBreakdownInfo[key].map((value) => {
                if (
                    value.status === JobStatus.CONTROLLER_ABORTED ||
                    value.status === JobStatus.HCP_ABORTED ||
                    value.status === JobStatus.COMPLETED
                ) {
                    return value;
                } else {
                    i++;
                    return { ...value, ...{ position: i } };
                }
            });

            if (this.focusedHCPBreakdownOfJobs[key]) {
                this.setHCPBreakdown(this.hcpsBreakdownInfo[key], key, true);
            }
        });
    };
}

export default MapStore;
