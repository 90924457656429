import { Redirect, Switch } from 'react-router-dom';

import { SentryRoute } from '../../../helpers/sentryRoute';
import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import JobsReport from '../../pages/reporting/JobsReport';

export default function Reporting() {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    if (!isFeatureEnabled('reporting')) {
        return <Redirect to="/page-not-found" />;
    }

    const renderRoutesWithContext = () => (
        <Switch>
            <Redirect exact from="/reporting" to="/reporting/jobs" />
            <SentryRoute path="/reporting/jobs" component={JobsReport} />
        </Switch>
    );

    return <section className="reporting">{renderRoutesWithContext()}</section>;
}
